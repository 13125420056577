import {priceConfigurationSchema} from './configurationSchema'
import {ValidationError} from 'yup'

/**
 * Get validated configuration from block
 */
export const retrieveConfig = function (block: HTMLElement) {
    const blockConfigurationString = block.querySelector('.blockPriceCalculator__moduleConfig')?.getAttribute('data-json')
    const blockContent = block.querySelector('.blockPriceCalculator__content')

    if (!blockConfigurationString) {
        throw new Error('BlockPriceCalculator: module configuration is missing')
    }

    if (!blockContent) {
        throw new Error('BlockPriceCalculator: content element')
    }

    const blockConfigurationJson = JSON.parse(blockConfigurationString)

    try {
        return priceConfigurationSchema.validateSync(blockConfigurationJson, {
            abortEarly: false,
            disableStackTrace: false,
        })
    } catch (err: unknown) {
        if (err instanceof ValidationError) {
            console.error('Ongeldige configuratie', err.errors)
            blockContent.innerHTML = err.errors.join(', ')
        }

        throw err
    }
}
