import {moduleState, priceCalculatorState} from '../block-price-calculator'
import {configurationSchema} from './configurationSchema'
import {findModuleConfigById, findSubmoduleConfigById} from './findModuleByConfigId'

/**
 * Update state - Calculate total price based on state
 */
export const validateRules = function (config: configurationSchema, state: priceCalculatorState, origin?: moduleState) {
    state.modules.forEach((module) => {
        if (module.type === 'module') {
            const configuration = findModuleConfigById(config, module.id)

            if (!configuration) {
                console.error(`BlockPriceCalculator: module configuration not found for ${module.id}`)
                return
            }

            if (configuration.minimal_users) {
                module.users = Math.max(module.users, configuration.minimal_users)
            }
        }

        // The users of te main module can't be less than then any other enabled module
        const maxUsers = state.modules.reduce((max, module) => {
            if (!module.enabled) {
                return max
            }

            return Math.max(max, module.users)
        }, 0)
        const mainModuleState = state.modules.find((module) => module.id === config.main_module_id) as moduleState
        mainModuleState.users = Math.max(mainModuleState.users, maxUsers)

        const externalChange = origin && origin.id !== module.id

        if (module.type === 'submodule') {
            const configuration = findSubmoduleConfigById(config, module.id)

            if (!configuration) {
                console.error(`BlockPriceCalculator: submodule configuration not found for ${module.id}`)
                return
            }

            const parentState = state.modules.find((parent) => parent.id === configuration.parent.id) as moduleState

            if (configuration.user_constraint === 'same_as_parent') {
                module.users = parentState.users
                module.readonly = true
            }

            if (configuration.user_constraint === 'maximum_parent') {
                if (externalChange) {
                    module.users = Math.min(module.users, parentState.users)
                } else {
                    parentState.users = Math.max(module.users, parentState.users)
                }
            }

            if (configuration.user_constraint === 'minimum_parent') {
                if (externalChange) {
                    module.users = Math.max(module.users, parentState.users)
                } else {
                    parentState.users = Math.min(module.users, parentState.users)
                }
            }

            if (configuration.minimal_users) {
                module.users = Math.max(module.users, configuration.minimal_users)
            }
        }
    })
}
