import {Behaviour, behaviours} from '../../../global/scripts/behaviours/behaviour'
import {reflectStateToDom} from './src/reflectStateToDom'
import {calculatePrices} from './src/calculatePrices'
import {addEventListeners} from './src/addEventListeners'
import {buildInitialState} from './src/buildInitialState'
import {validateRules} from './src/validateRules'
import {retrieveConfig} from './src/retrieveConfig'
import {Sticky} from 'sticky-ts'

export type moduleState = {
    id: string
    enabled: boolean
    readonly: boolean
    users: number
    type: 'module' | 'submodule'
}

export type priceCalculatorState = {
    modules: moduleState[]
    totals: {
        price: number
        modules: number
        users: number
    }
}

const BlockPriceCalculatorBehaviour: Behaviour = (function () {
    const attach = function (context: HTMLElement) {
        const blocks = context.querySelectorAll<HTMLElement>('.blockPriceCalculator')

        if (blocks.length === 0) {
            return
        }

        blocks.forEach((block) => init(block))
    }

    const init = function (block: HTMLElement) {
        new Sticky('.blockPriceCalculator__totals')

        // tippy('[data-tooltip]', {
        //     appendTo: 'parent',
        //     plugins: [followCursor],
        //     allowHTML: true,
        //     followCursor: true,
        //     arrow: false,
        //     content(reference) {
        //         const id = reference.getAttribute('data-tooltip')
        //         if (!id) {
        //             return ''
        //         }
        //         const template = document.getElementById(id)
        //         if (!template) {
        //             return ''
        //         }
        //         return template.innerHTML
        //     },
        // })

        const config = retrieveConfig(block)
        const state = buildInitialState(config)
        calculatePrices(config, state)
        reflectStateToDom(block, state)

        addEventListeners(block, state, function (module: moduleState) {
            validateRules(config, state, module)
            validateRules(config, state, module)
            calculatePrices(config, state)
            reflectStateToDom(block, state)
        })
    }

    return {
        attach: attach,
    }
})()

behaviours.addBehaviour(BlockPriceCalculatorBehaviour)
