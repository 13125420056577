import {moduleState, priceCalculatorState} from '../block-price-calculator'

export const addEventListeners = function (block: HTMLElement, state: priceCalculatorState, onChange: (state: moduleState) => void) {
    const inputs = block.querySelectorAll<HTMLInputElement>('input')

    inputs.forEach((input) => {
        input.addEventListener('change', (event) => {
            const target = event.target as HTMLInputElement
            const moduleId = target.getAttribute('data-module-id')

            if (!moduleId) {
                console.error('BlockPriceCalculator: module id not found')
                return
            }

            const module = state.modules.find((module) => module.id === moduleId)

            if (!module) {
                console.error('BlockPriceCalculator: module not found')
                return
            }

            if (input.type === 'checkbox') {
                module.enabled = input.checked
            } else if (input.type === 'number') {
                const value = parseInt(input.value)
                if (isNaN(value)) {
                    if (input.value == '') {
                        input.value = '0'
                    } else {
                        input.value = module.users.toString()
                    }
                }
                module.users = parseInt(input.value)
            }

            onChange(module)
        })
    })
}
