import {configurationSchema, moduleConfiguration, subModuleConfiguration} from './configurationSchema'

export const findModuleConfigById = function (config: configurationSchema, id: string) {
    const module = config.modules.find((module) => module.id === id)

    if (module) {
        return module as moduleConfiguration
    }

    return null
}

export const findSubmoduleConfigById = function (config: configurationSchema, id: string): (subModuleConfiguration & {parent: moduleConfiguration}) | null {
    for (const module of config.modules) {
        if (!module.submodules) {
            continue
        }

        const submodule = module.submodules.find((submodule) => submodule.id === id)

        if (submodule) {
            return {
                ...submodule,
                parent: module,
            }
        }
    }

    return null
}

export const findConfigById = function (config: configurationSchema, id: string) {
    return findModuleConfigById(config, id) || findSubmoduleConfigById(config, id)
}
