import {configurationSchema} from './configurationSchema'
import {moduleState, priceCalculatorState} from '../block-price-calculator'

/**
 * Build state from configuration
 * @param config
 */
export const buildInitialState = function (config: configurationSchema): priceCalculatorState {
    const modules = config.modules.map((module) => {
        return {
            id: module.id,
            enabled: module.enabled_by_default || config.main_module_id === module.id,
            users: module.default_users ?? 0,
            type: 'module' as moduleState['type'],
            readonly: false,
        }
    })

    config.modules.forEach((module) => {
        if (!module.submodules) {
            return
        }

        module.submodules.forEach((submodule) => {
            modules.push({
                id: submodule.id,
                enabled: submodule.enabled_by_default,
                users: submodule.default_users,
                type: 'submodule' as moduleState['type'],
                readonly: false,
            })
        })
    })

    return {
        modules,
        totals: {
            users: 0,
            modules: 0,
            price: 0,
        },
    }
}
