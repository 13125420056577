import {moduleState, priceCalculatorState} from '../block-price-calculator'
import {findModuleConfigById, findSubmoduleConfigById} from './findModuleByConfigId'
import {configurationSchema} from './configurationSchema'

/**
 * Update state - Calculate total price based on state
 */
export const calculatePrices = function (config: configurationSchema, state: priceCalculatorState) {
    state.totals = {
        price: 0,
        modules: 0,
        users: 0,
    }

    // Calculate total price
    state.modules.map((module) => {
        const moduleConfiguration = findModuleConfigById(config, module.id)
        const submoduleConfiguration = findSubmoduleConfigById(config, module.id)
        const configuration = moduleConfiguration || submoduleConfiguration

        if (!configuration) {
            console.error(`BlockPriceCalculator: module configuration not found for ${module.id}`)
            return
        }

        const parentModuleId = submoduleConfiguration?.parent?.id
        const parentModuleState = (parentModuleId && state.modules.find((parent) => parent.id === parentModuleId)) || null

        if (!module.enabled || (parentModuleState && !parentModuleState.enabled)) {
            return
        }

        const users = Math.max(module.users - (configuration?.free_users || 0), 0)

        state.totals.price += configuration.base_price + configuration.price_per_user * users
        state.totals.modules += 1
    })

    // Calculate total users
    const mainModuleState = state.modules.find((mainModule) => mainModule.id === config.main_module_id) as moduleState
    state.totals.users = mainModuleState.users
}
