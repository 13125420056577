import {priceCalculatorState} from '../block-price-calculator'

export const reflectStateToDom = function (block: HTMLElement, state: priceCalculatorState) {
    const totalPrice = block.querySelector('.blockPriceCalculator__totalPrice')
    const totalUsers = block.querySelector('.blockPriceCalculator__totalUsers')
    const totalModules = block.querySelector('.blockPriceCalculator__totalModules')

    if (!totalPrice || !totalUsers || !totalModules) {
        console.error('BlockPriceCalculator: total elements not found')
        return
    }

    const formattedPrice = new Intl.NumberFormat('nl-NL', {style: 'currency', currency: 'EUR'}).format(state.totals.price)

    totalPrice.innerHTML = `${formattedPrice.replace(',', '<span>,')}</span>`
    totalUsers.innerHTML = `${state.totals.users.toString()} gebruiker${state.totals.users > 1 ? 's' : ''}`
    totalModules.innerHTML = `${state.totals.modules.toString()} module${state.totals.modules > 1 ? 's' : ''}`

    state.modules.forEach((module) => {
        const input = block.querySelector<HTMLInputElement>(`[data-module-id="${module.id}"][type=number]`)

        if (!input) {
            return
        }

        input.value = module.users.toString()
        input.disabled = !module.enabled || module.readonly
    })
}
