import {array, boolean, number, object, string} from 'yup'

const subModuleSchema = object({
    free_users: number().optional(),
    id: string().required(),
    title: string().required(),
    base_price: number().required(),
    price_per_user: number().required(),
    minimal_users: number().required(),
    default_users: number().required(),

    // Flags
    enabled_by_default: boolean().required(),
    user_constraint: string().oneOf(['same_as_parent', 'maximum_parent', 'minimum_parent', 'none']).required(),
})

export const moduleSchema = object({
    id: string().required(),
    title: string().required(),
    base_price: number().required(),
    price_per_user: number().required(),
    enabled_by_default: boolean().required(),
    minimal_users: number().optional(),
    default_users: number().optional(),
    required: boolean().optional(),
    free_users: number().optional(),
    submodules: array().of(subModuleSchema).optional(),
})

export const priceConfigurationSchema = object({
    modules: array().of(moduleSchema).required(),
    main_module_id: string().required(),
}).test('mainModuleExists', 'Main module not found in modules', function (value) {
    return value.modules.some((module) => module.id === value.main_module_id)
})

export type configurationSchema = ReturnType<typeof priceConfigurationSchema.validateSync>
export type moduleConfiguration = ReturnType<typeof moduleSchema.validateSync>
export type subModuleConfiguration = ReturnType<typeof subModuleSchema.validateSync>
